<template>
  <div class="imgBanner" :class="{ 'position-relative': (page !== 'product-list') }">
    <img v-if="(page === 'index')" src="/images/banner/homepage-img.jpg"/>
    <!--<img v-if="(page === 'index')" src="/images/banner/259/Groupe de masques 259.png"/>-->
    <template v-if="page !== 'product-detail'">
      <div class="imgBannerText" v-if="page === 'index'">
        <span class="chiffre">-30%</span> <br/>
        <span>sur une sélection de produit</span>
      </div>
      <div class="findUser" v-if="page !== 'product-list'">
        <span>Que recherchez vous ?</span>
        <div class="divInputSearch">
          <div class="iconSearch" v-if="page === 'index'">
            <img src="/images/search-icon-gray/Groupe-1178.png" alt="">
          </div>
          <input class="inputSearchByKeyWord" type="text" placeholder="RECHERCHE PAR MOTS CLÉS" v-if="page === 'index'">
          <button class="btnSearchByKeyWord">Rechercher <i class="fa-solid fa-chevron-right"></i></button>
        </div>
      </div>
      <div v-else class="findUser" :class="{ blackFond: (page === 'product-list') }">
        <!--        <span>Que recherchez vous ?</span>-->
        <button class="btnCategoryProduct" @click="showCategory()">
          Catégorie de produit <i class="fa-solid fa-chevron-right"></i>
        </button>
        <div class="selectCategory" :class="{ show: showListCategory }">
          <ul>
            <li class="active">Réparation</li>
            <li>Préparation</li>
            <li>Finition</li>
            <li>E.P.I</li>
            <li>Matériels</li>
          </ul>
        </div>
        <button class="btnTypeProduct">Type de produit <i
            class="fa-solid fa-chevron-right"></i>
        </button>
        <button class="btnOffre">Nos offres du moment <i
            class="fa-solid fa-chevron-right"></i>
        </button>
        <div class="divMoreFilter">
          <button class="btnMoreFilter">+ de filtres <i class="fa-solid fa-chevron-right"></i></button>
        </div>
        <button class="btnSearchByKeyWord">Rechercher <i class="fa-solid fa-chevron-right"></i></button>
      </div>
    </template>
  </div>
</template>
<script setup>
defineProps({
  page: String
})

const showListCategory = ref(false)

const showCategory = () => {
  showListCategory.value = !showListCategory.value
}
</script>
<style scoped>
img {
  width: 100%;
  max-height: 685px;
  object-fit: cover;
}

.position-relative {
  position: relative;
}

.imgBannerText {
  position: absolute;
  top: 43%;
  left: 23%;
  color: #D81627;
  font-size: 2rem;
  font-family: Barlow;
  font-weight: 600;
  text-transform: uppercase;
}

.imgBannerText .chiffre {
  font-size: 10rem;
}

.blackFond {
  //background-color: transparent !important;
  /* opacity: 0.6; */
  top: 9% !important;
  //margin-left: 20rem !important;
  width: 16% !important;
  padding-left: 0 !important;
  padding-bottom: 3.6rem !important;
}

.blackFond::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .6;
  z-index: -1;
  background: #0D0C0C;
}

.findUser {
  background-color: #818F9B;
  display: flex;
  flex-direction: column;
  font-family: Barlow;
  padding-bottom: 2rem;
  padding-left: 20rem;
  width: 65%;
  position: absolute;
  top: 88%;
  z-index: 0;
}

.findUser span {
  font-size: 50px;
  color: #fff;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.findUser .inputSearchByKeyWord {
  width: 68.3%;
  height: 80px;
  font-family: Barlow, sans-serif;
  border: none;
  font-size: 22px;
  padding-left: 4rem;
}

.findUser .inputSearchByKeyWord:focus-visible {
  outline: none;
}

.findUser .inputSearchByKeyWord::placeholder {
  font-weight: 600;
}

.findUser .btnSearchByKeyWord {
  background-color: #FFD11D;
  border: none;
  padding: 0.8rem 4rem;
  font-family: Barlow;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  height: 80px;
}

.findUser.blackFond .btnSearchByKeyWord {
  padding: 0.8rem 1rem;
}

.findUser .btnCategoryProduct {
  background-color: #fff;
  border: none;
  padding: 0.8rem 1rem;
  font-family: Barlow;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  height: 80px;
}

.findUser .btnTypeProduct {
  background-color: #fff;
  border: none;
  padding: 0.8rem 1rem;
  font-family: Barlow;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  height: 80px;
}

.findUser .btnOffre {
  background-color: #C30726;
  border: none;
  padding: 0.8rem 1rem;
  font-family: Barlow;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  height: 80px;
  color: #fff;
}

.findUser .btnMoreFilter {
  background-color: transparent;
  border: none;
  padding: 0.8rem 1rem;
  font-family: Barlow;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  height: 80px;
  color: #fff;
}

.findUser .btnSearchByKeyWord i {
  font-size: 20px;
  margin-left: 1rem;
}

.findByFilter {

}

.divInputSearch {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  position: relative;
}

.iconSearch {
  position: absolute;
  top: 28%;
  left: 2%;
}

.divMoreFilter {
  text-align: end;
}

.selectCategory {
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
  display: none;
}

.selectCategory ul {
  list-style-type: none;
  padding: 0;
}

.selectCategory ul li {
  font-size: 25px;
  font-family: Barlow;
  text-transform: uppercase;
  padding: 0.3rem;
  font-weight: 600;
}

.selectCategory ul li.active {
  background-color: #FFD11D;
}

.show {
  display: block;
}

button {
  cursor: pointer;
}
</style>